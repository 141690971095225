import { Spinner } from "react-bootstrap";
import logo from "../img/rpa-icon.png";

function LoadingPage() {
  return (
    <div className="loading-page">
      <div className="loading-page__container">
        <div className="loading-page__container__img">
          <img src={logo} alt="logo" className="scale-animation" />
        </div>
        <div className="loading-page__container__spinner" style={{ marginTop: "15px", textAlign: "center" }}>
          <Spinner as="span" animation="border" variant="light" size="sm" role="status" aria-hidden="true" />
        </div>
      </div>
    </div>
  );
}

export default LoadingPage;
