import React, { useEffect, useState } from "react";
import dataService from "../services/dataService";
import logo from "../img/rpa-icon.png";
import { Alert, Button, Card, Col, Container, FloatingLabel, Form, Modal, Row, Spinner, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

function Verify() {
  const [firma, setFirma] = useState({});
  const [codeInput, setCodeInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [inputNotChange, setInputNotChange] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  function formatDateTime(val, timeIncluded) {
    var date = new Date(val);
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    day = day < 10 ? "0" + day : day;
    month = month < 10 ? "0" + month : month;
    if (timeIncluded) {
      var hour = date.getHours();
      var min = date.getMinutes();
      var ampm = hour >= 12 ? "pm" : "am";
      hour = hour % 12;
      hour = hour ? hour : 12;
      hour = hour < 10 ? "0" + hour : hour;
      min = min < 10 ? "0" + min : min;
    }
    var newDate = day + "/" + month + "/" + year + (timeIncluded ? " " + hour + ":" + min + " " + ampm : "");
    return newDate;
  }

  const verify = async (code) => {
    try {
      setIsLoading(true);
      const firmaRes = await dataService.verifyFirma(code);
      if (firmaRes && firmaRes.data.status === "success") {
        const imgRes = await dataService.findFirmaImgByCodigo(firmaRes.data.registro.idFirma);
        firmaRes.data.registro.img = imgRes.data;
      }
      setFirma(firmaRes.data);
      setIsLoading(false);
      setInputNotChange(true);
    } catch (error) {
      setIsLoading(false);
      setFirma({ status: "error", message: "No se pudo verificar la firma" });
    }
  };

  useEffect(() => {}, []);
  return (
    <>
      <Container fluid className="pageResponse">
        <style>{`body{background-color: rgba(209, 209, 209, .3);}`}</style>
        <Row xs="auto" className="center">
          <Col>
            <Card className="mt-3 text-center center">
              <Card.Body>
                <div className="icon-logo mb-3">
                  <img alt="logo" src={logo} width="70px" />
                </div>
                <Card.Title>Verificador de firmas</Card.Title>
                <Alert variant="info"> Ingresa el c&oacute;digo que se encuentra adjunto a la firma</Alert>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    verify(e.target.code.value);
                  }}>
                  <Row xs="auto" className="center">
                    <FloatingLabel className="mt-2 p-0 text-center" controlId="code" label="Ingresa el c&oacute;digo">
                      <Form.Control
                        type="text"
                        name="code"
                        placeholder="Ingresa el c&oacute;digo"
                        onChange={(e) => {
                          setInputNotChange(false);
                          setCodeInput(e.target.value.toUpperCase());
                        }}
                        value={codeInput}
                        disabled={isLoading}
                      />
                    </FloatingLabel>
                  </Row>
                  <Button variant="primary" className="mt-2" type="submit" disabled={isLoading || codeInput.length === 0 || inputNotChange}>
                    {isLoading ? <Spinner as="span" animation="border" variant="light" size="sm" role="status" aria-hidden="true" /> : "Verificar"}
                  </Button>
                </Form>
                <Row xs="auto" className="center mt-4">
                  <Col>
                    {firma.status === "success" && firma.registro && (
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>Emisor</th>
                            <th>Motivo</th>
                            <th>Fecha</th>
                            <th>App</th>
                            <th>Detalle</th>
                          </tr>
                        </thead>
                        <tbody className="table-group-divider align-middle">
                          <tr>
                            <td>{(firma.user.apellido ? firma.user.apellido + ", " : "") + firma.user.nombre}</td>
                            <td>{firma.registro.motivo}</td>
                            <td>{firma.registro.fecha ? formatDateTime(firma.registro.fecha, true) : ""}</td>
                            <td>{firma.registro.modoUsuario === "colab" ? "Intranet" : "Sist. Colab"}</td>
                            <td>
                              <Button variant="link" size="sm" onClick={openModal}>
                                <FontAwesomeIcon icon={faEye} />
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {firma && (
          <Row xs="auto" className="center mt-2">
            {firma.status === "error" && <Alert variant="danger">No se pudo verificar la firma</Alert>}
            {firma.status === "warning" && <Alert variant="warning">Firma no registrada</Alert>}
          </Row>
        )}
      </Container>
      <Modal show={showModal} onHide={closeModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Detalle de la firma</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row xs="auto" className="center">
            <Col>
              {firma.status === "success" && firma.registro && (
                <div>
                  <p>
                    <b>Fecha:</b> {firma.registro.fecha ? formatDateTime(firma.registro.fecha, true) : ""}
                  </p>
                  <p>
                    <b>Motivo:</b> {firma.registro.motivo}
                  </p>
                  <p>
                    <b>Nombre Colaborador:</b> {firma.registro.nombre}
                  </p>
                  <p>
                    <b>Documento ID:</b> {firma.registro.ID}
                  </p>
                  <p>
                    <b>RUC Colaborador:</b> {firma.registro.RUC}
                  </p>
                  <p>
                    <b>Empresa:</b> {firma.registro.empresa}
                  </p>
                  <p>
                    <b>Puesto:</b> {firma.registro.puesto}
                  </p>
                  <p>
                    <b>Fecha Inicio:</b> {formatDateTime(firma.registro.fechaI, false)}
                  </p>
                  <p>
                    <b>Fecha Fin:</b> {formatDateTime(firma.registro.fechaF, false)}
                  </p>
                  <p>
                    <b>Firma:</b>
                  </p>
                  <div className="text-center">
                    <img alt="firma" src={firma.registro.img} width="80%" />
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Verify;
