import { faRectangleList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/navbar";
import dataService from "../services/dataService";

function Dashboard() {
  const [info, setInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  let navigate = useNavigate();

  const expireSession = () => {
    setTimeout(() => {
      sessionStorage.removeItem("user");
      navigate("/login", { replace: true });
    }, 3500);
  };

  useEffect(() => {
    (async () => {
      try {
        if (localStorage.getItem("user") !== null) {
          let loggedUser = JSON.parse(localStorage.getItem("user"));
          let res = await dataService.getApps(loggedUser.ext);
          if (res.status === 200) {
            setInfo(res.data);
          }
        }
        setIsPageLoading(false);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);
  return (
    <>
      <Navbar isLoading={isPageLoading} />
      <div className="container-fluid page-content pb-4">
        <Row xs="auto" className="d-flex align-items-end justify-content-center my-3">
          <Col xs="auto">
            <h3 className="text-center">Dashboard Aplicaciones</h3>
          </Col>
        </Row>
        {!isPageLoading && (
          <Row xs="auto" className="d-flex align-items-end justify-content-center">
            {info.length > 0 &&
              info.map((item, index) => (
                <React.Fragment key={index}>
                  <Col xs="1"></Col>
                  <Col xs="4" className="d-flex justify-content-center my-5">
                    <a className="btn btn-primary btn-apps d-flex align-items-center" href={"/" + item.ruta}>
                      <b>
                        <span className="ms-2">{item.nombre}</span>
                      </b>
                      <FontAwesomeIcon icon={faRectangleList} className="mx-2" style={{ fontSize: "1.25em" }} />
                    </a>
                  </Col>
                  <Col xs="1"></Col>
                </React.Fragment>
              ))}
          </Row>
        )}
        {!isPageLoading && info.length === 0 && (
          <Row xs="auto" className="d-flex align-items-end justify-content-center my-3">
            <Col xs="auto">
              <h3 className="text-center">No tienes aplicaciones asignadas</h3>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
}

export default Dashboard;
