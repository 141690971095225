import React, { useEffect, useState } from "react";
import { Form, Button, InputGroup, FormControl, Alert } from "react-bootstrap";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useParams } from "react-router-dom";
import userService from "../services/dataService";
import Swal from "sweetalert2";

function ResetPassword() {
  const [pass, setPass] = useState("");
  const [pass2, setPass2] = useState("");
  const [info, setInfo] = useState(false);
  const [connError, setConnError] = useState(false);
  const [pwdError, setPwdError] = useState(false);
  const [badMailLostPwd, setBadMailLostPwd] = useState(false);
  let navigate = useNavigate();
  const { token } = useParams();

  const onChangePass = (e, mod) => {
    setConnError(false);
    if (mod === "2") setPass2(e.target.value);
    else setPass(e.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      if (pass !== pass2) {
        setPwdError(true);
        return;
      } else {
        setPwdError(false);
        await userService.update({ tkn: token, password: pass });
        Swal.fire({
          icon: "success",
          title: "¡Listo!",
          confirmButtonColor: "#357ba3",
          html: "<p>Se completo la actualizacion de la contraseña.</p>",
        }).then((result) => {
          if (result.isConfirmed) {
            setConnError(false);
            navigate("/", { replace: true });
          }
        });
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 400) {
        setConnError(false);
        setBadMailLostPwd(true);
      } else {
        setConnError(true);
        console.log(error);
      }
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const res = await userService.getResetPasswordTk(token);
        setInfo(true);
        if (res.status === 200) {
          setBadMailLostPwd(false);
        } else {
          setBadMailLostPwd(true);
        }
      } catch (error) {
        setInfo(true);
        setBadMailLostPwd(true);
      }
    })();
  }, [token]);

  return (
    <div className="container-fluid login">
      <div className="center">
        {info && (
          <div className="login-form">
            {!badMailLostPwd && (
              <Form onSubmit={onSubmit}>
                <InputGroup
                  className="mb-3"
                  onChange={(e) => {
                    onChangePass(e);
                  }}>
                  <FormControl type="password" placeholder="Nueva Contraseña" autoComplete="reset1" />
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faLock} />
                  </InputGroup.Text>
                </InputGroup>
                <InputGroup
                  className="mb-3"
                  onChange={(e) => {
                    onChangePass(e, "2");
                  }}>
                  <FormControl type="password" placeholder="Repite la Contraseña" autoComplete="reset2" />
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faLock} />
                  </InputGroup.Text>
                </InputGroup>
                <Button variant="primary" type="submit" className="form-control">
                  Cambiar Contraseña
                </Button>
                <Alert show={pwdError} key={"conn"} variant={"danger"} className="mt-3 mb-0 text-center">
                  Las contraseñas no coinciden
                </Alert>
                <Alert show={connError} key={"conn"} variant={"danger"} className="mt-3 mb-0 text-center">
                  Se experimentan dificultades tecnicas, intenta mas tarde
                </Alert>
              </Form>
            )}
            {badMailLostPwd && ( // Si el token no es valido
              <div className="text-center">
                <h4>El token no es valido</h4>
                <p>Intenta de nuevo</p>
                <Button variant="primary" onClick={() => navigate("/login", { replace: true })}>
                  Ir al inicio
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default ResetPassword;
