import React, { Component } from "react";
import logo from "../img/logo-rpa.png";
import { Dropdown, Navbar, Container, Button, Spinner, Modal, Form, Alert } from "react-bootstrap";
import { faUser, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, Navigate } from "react-router-dom";
import LoadingPage from "./loadingPage";
import userservice from "../services/dataService";

class CNavbar extends Component {
  state = {
    loggedUser: null,
    isShow: false,
    isLoading: false,
    pass1: "",
    pass2: "",
  };

  handleSave = () => {
    this.setState({ isLoading: true });
    userservice
      .update({ id: this.state.loggedUser.ext, password: this.state.pass1 })
      .then((res) => {
        if (res.status === 200) {
          this.setState({ isShow: false });
          this.setState({ isLoading: false });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleClose = () => {
    this.setState({ isShow: false });
  };

  componentDidMount() {
    if (localStorage.getItem("user") !== null) {
      this.setState({ loggedUser: JSON.parse(localStorage.getItem("user")) });
    }
  }

  render() {
    const { loggedUser, isShow, isLoading } = this.state;
    return (
      <>
        {localStorage.getItem("user") === null && <Navigate to="/login" replace={true} />}
        {this.props.isLoading && <LoadingPage />}
        <Navbar bg="light" fixed="top" className="shadow" style={{ flexDirection: "column" }}>
          <Container fluid className="row-cols-auto">
            <Navbar.Brand href="/" style={{ display: "flex", padding: "0px" }}>
              <img alt="logo" src={logo} height="60" id="nav-logo-rpa" className="d-inline-block align-top me-3" />
            </Navbar.Brand>
            <div className="col text-end">
              <Dropdown className="btn-user">
                <Dropdown.Toggle variant="success" id="user">
                  <FontAwesomeIcon icon={faUser} />
                  <span>{" Hola " + (loggedUser && loggedUser.name ? (loggedUser.name.indexOf(" ") > -1 ? loggedUser.name.substring(0, loggedUser.name.indexOf(" ")) : loggedUser.name) : "")}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ left: "auto", right: 0 }}>
                  <Dropdown.Item
                    onClick={() => {
                      this.setState({ isShow: true });
                    }}>
                    Cambiar Contraseña
                  </Dropdown.Item>
                  {loggedUser && Number(loggedUser.rol) === 1 && (
                    <Dropdown.Item as={Link} to="/users">
                      Editar Usuarios
                    </Dropdown.Item>
                  )}
                  {/*loggedUser && Number(loggedUser.rol) === 1 && (
                    <Dropdown.Item as={Link} to="/validacion">
                      Validacion Firmas
                    </Dropdown.Item>
                  )*/}
                  <Dropdown.Item
                    onClick={() => {
                      localStorage.removeItem("user");
                      window.location.reload();
                    }}>
                    Cerrar Sesion
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Container>
        </Navbar>
        <Modal centered show={isShow} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Cambiar Contraseña</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Alert variant={"warning"}>Se requiere al menos 8 caracteres.</Alert>
            <Form>
              <Form.Group className="mb-3" controlId="pass1">
                <Form.Label>Nueva Contraseña</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) => {
                    this.setState({ pass1: e.target.value });
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="rpass1">
                <Form.Label>Confirmar Contraseña</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) => {
                    this.setState({ pass2: e.target.value });
                  }}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Cerrar
            </Button>
            <Button variant="primary" disabled={isLoading || this.state.pass1 !== this.state.pass2 || this.state.pass1.length < 8} onClick={this.handleSave}>
              {isLoading === true ? "" : "Guardar "}
              {isLoading === true ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <FontAwesomeIcon icon={faSave} />}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default CNavbar;
