import React, { useState } from "react";
import { Form, Button, InputGroup, FormControl, Alert, Spinner } from "react-bootstrap";
import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import logo from "../img/logo-login-rpa.png";
import userService from "../services/dataService";
import Swal from "sweetalert2";

function Login() {
  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");
  const [lostPasword, setLostPassword] = useState(false);
  const [badLogin, setBadLogin] = useState(false);
  const [connError, setConnError] = useState(false);
  const [badMailLostPwd, setBadMailLostPwd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let navigate = useNavigate();

  const onChangeUser = (e) => {
    setBadLogin(false);
    setConnError(false);
    setUser(e.target.value);
  };

  const onChangePass = (e) => {
    setBadLogin(false);
    setConnError(false);
    setPass(e.target.value);
  };

  const onSubmitLostPassword = (e) => {
    e.preventDefault();
    let data = {
      mail: user,
    };
    setUser("");
    setIsLoading(true);
    userService
      .lostPassword(data)
      .then((response) => {
        if (response.data) {
          Swal.fire({
            icon: "success",
            title: "¡Listo!",
            confirmButtonColor: "#357ba3",
            html: "<p>Se ha enviado un link de recuperacion al correo ingresado.</p>",
          }).then((result) => {
            if (result.isConfirmed) {
              setLostPassword(false);
              setConnError(false);
              setBadMailLostPwd(false);
            }
          });
        }
        setIsLoading(false);
      })
      .catch((e) => {
        if (e.response.status === 404) {
          setBadMailLostPwd(true);
        } else if (e.response.status === 500) {
          setConnError(true);
        }
        setIsLoading(false);
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let data = {
      user: user,
      pass: pass,
    };
    setIsLoading(true);
    userService
      .login(data)
      .then((response) => {
        if (response.data) {
          const user = {
            name: response.data.data1.nombre,
            mail: response.data.data1.mail,
            rol: response.data.data1.rol,
            ext: response.data.data1.id,
            tkn: response.data.token,
          };
          response.data.apps.forEach((app) => {
            user[app.ruta] = app.rol;
          });
          localStorage.setItem("user", JSON.stringify(user));
          navigate("/", { replace: true });
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        if (e.response.status === 404) {
          setBadLogin(true);
        } else if (e.response.status === 500) {
          setConnError(true);
          console.log(e);
        }
        setIsLoading(false);
      });
  };

  if (localStorage.getItem("user") !== null) {
    navigate("/", { replace: true });
  }

  return (
    <div className="container-fluid login">
      <div className="center">
        <div className="login-form">
          <div className="center">
            <img src={logo} className="img-fluid mb-5" alt="logo" width="250px" />
          </div>
          {!lostPasword && (
            <Form onSubmit={onSubmit}>
              <InputGroup className="mb-3" onChange={onChangeUser}>
                <FormControl type="email" placeholder="Nombre de usuario" autoComplete="username" />
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faEnvelope} />
                </InputGroup.Text>
              </InputGroup>
              <InputGroup className="mb-3" onChange={onChangePass}>
                <FormControl type="password" placeholder="Contraseña" autoComplete="current-password" />
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faLock} />
                </InputGroup.Text>
              </InputGroup>
              <Button variant="primary" type="submit" className="form-control" disabled={isLoading}>
                {isLoading === true ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Iniciar sesión"}
              </Button>
              <Button
                variant="link"
                className="text-center d-block mt-3 mx-auto"
                style={{ color: "#226699", fontWeight: 500 }}
                onClick={(e) => {
                  setLostPassword(true);
                  setConnError(false);
                  setBadLogin(false);
                }}>
                ¿Olvidaste tu contraseña?
              </Button>
              <Alert show={badLogin} key={"bad"} variant={"danger"} className="mt-3 mb-0 text-center">
                Revisa tu usuario y contraseña
              </Alert>
              <Alert show={connError} key={"conn"} variant={"danger"} className="mt-3 mb-0 text-center">
                Se experimentan dificultades tecnicas, intenta mas tarde
              </Alert>
            </Form>
          )}
          {lostPasword && (
            <Form onSubmit={onSubmitLostPassword}>
              <p>Recuperar Contraseña</p>
              <InputGroup className="mb-3" onChange={onChangeUser}>
                <FormControl type="email" placeholder="Email" autoComplete="emaillostpwd" />
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faEnvelope} />
                </InputGroup.Text>
              </InputGroup>
              <Button variant="primary" type="submit" className="form-control" disabled={isLoading}>
                {isLoading === true ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Recuperar contraseña"}
              </Button>
              <Button
                variant="link"
                className="text-center d-block mt-3 mx-auto"
                style={{ color: "#226699", fontWeight: 500 }}
                onClick={(e) => {
                  setLostPassword(false);
                  setConnError(false);
                  setBadMailLostPwd(false);
                }}>
                Regresar
              </Button>
              <Alert show={badMailLostPwd} key={"bad"} variant={"danger"} className="mt-3 mb-0 text-center">
                No se encuentra el correo ingresado
              </Alert>
              <Alert show={connError} key={"conn"} variant={"danger"} className="mt-3 mb-0 text-center">
                Se experimentan dificultades tecnicas, intenta mas tarde
              </Alert>
            </Form>
          )}
        </div>
      </div>
    </div>
  );
}

export default Login;
