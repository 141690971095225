import React, { useEffect, useState } from "react";
import Navbar from "../components/navbar";
import userService from "../services/dataService";
import { Form, Row, Col, Spinner, Modal, Button, Table } from "react-bootstrap";
import { faPlus, faPencil, faSave, faTrash, faListDots } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";

function Users() {
  const [userData, setUserData] = useState([]);
  const [appsData, setAppsData] = useState([]);
  const [userApps, setUserApps] = useState([]);
  const [idx, setIdx] = useState(0);
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [mail2, setMail2] = useState("");
  const [pass, setPass] = useState("");
  const [rol, setRol] = useState(2);
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [show, setShow] = useState(false);
  const [showModalApp, setShowModalApp] = useState(false);
  const [existValue, setExistValue] = useState(false);
  const user = JSON.parse(localStorage.getItem("user")) || {};

  const handleClose = () => {
    setShow(false);
    setIsEditing(false);
    setExistValue(false);
    resetForm();
    setIsLoading(false);
  };
  const handleAppsModalClose = () => {
    setShowModalApp(false);
    setIsLoading(false);
    setUserApps([]);
  };
  const handleSave = async () => {
    try {
      setIsLoading(true);
      setExistValue(false);
      if (userData.some((e) => e.mail.toUpperCase() === mail.toUpperCase())) {
        setExistValue(true);
        setIsLoading(false);
        return;
      }
      let dat = {
        nombre: name,
        mail: mail,
        password: pass,
        rol: rol || 2,
      };
      await userService.create(dat);
      const res = await userService.getAll();
      setUserData(res.data);
      setShow(false);
      resetForm();
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const handleShow = () => setShow(true);
  const handleUpdate = async () => {
    try {
      setIsLoading(true);
      setExistValue(false);
      if (mail2.toUpperCase() !== mail.toUpperCase() && userData.some((e) => e.mail.toUpperCase() === mail.toUpperCase())) {
        setExistValue(true);
        setIsLoading(false);
        return;
      }
      let dat = {
        nombre: name,
        mail: mail,
        rol: rol || 2,
        id: idx,
      };
      await userService.update(dat);
      const res = await userService.getAll();
      setUserData(res.data);
      setShow(false);
      setIsEditing(false);
      resetForm();
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const handleDel = async (id, idx) => {
    const result = await Swal.fire({
      title: "¿Está seguro?",
      text: "Si procede podria perder datos importantes",
      icon: "warning",
      confirmButtonColor: "#357ba3",
      showCancelButton: true,
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "Cancelar",
    });
    if (result.isConfirmed) {
      try {
        setIsLoading(true);
        if (user.ext === id) {
          let aux = [...userData];
          aux[idx].noDelete = true;
          setUserData(aux);
          setIsLoading(false);
          return;
        }
        await userService.delete(id);
        const res = await userService.getAll();
        setUserData(res.data);
        setIsLoading(false);
      } catch (error) {
        if (error.response.status === 403) {
          let aux = [...userData];
          aux[idx].noDelete = true;
          setUserData(aux);
        } else {
          console.log(error);
        }
        setIsLoading(false);
      }
    }
  };
  const showAppsModal = async (id) => {
    try {
      setIsLoading(true);
      const res = await userService.getApps(id);
      let aux = [];
      if (res.data.length === 0) appsData.forEach((e) => aux.push({ id: e.id, nombre: e.nombre, rol: 2, checked: false }));
      else
        appsData.forEach((e) => {
          let i = res.data.findIndex((f) => f.idApp === e.id);
          if (i > -1) aux.push({ id: e.id, nombre: e.nombre, rol: res.data[i].rol, checked: true });
          else aux.push({ id: e.id, nombre: e.nombre, rol: 2, checked: false });
        });
      console.log(aux);
      setUserApps(aux);
      setShowModalApp(true);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const handleAppsCheck = (id) => {
    let aux = [...userApps];
    aux.forEach((e) => {
      if (e.id === id) e.checked = !e.checked;
    });
    setUserApps(aux);
  };
  const handleAppsRol = (ix, id) => {
    let aux = [...userApps];
    aux[ix].rol = id;
    setUserApps(aux);
  };
  const handleUpdateUserApps = async () => {
    try {
      setIsLoading(true);
      let aux = [];
      userApps.forEach((e) => {
        if (e.checked) aux.push({ idApp: e.id, rol: e.rol, idUsuario: idx });
      });
      await userService.updateUserApps({ id: idx, apps: aux });
      handleAppsModalClose();
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const resetForm = () => {
    setIdx(0);
    setName("");
    setMail("");
    setMail2("");
    setPass("");
    setRol(2);
  };
  useEffect(() => {
    (async () => {
      try {
        const res1 = await userService.getAll();
        setUserData(res1.data);
        setTimeout(() => {
          setIsPageLoading(false);
        }, 850);
        const res2 = await userService.getAllApps();
        setAppsData(res2.data);
      } catch (error) {
        console.log(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Navbar isLoading={isPageLoading} />
      <div className="container-fluid page-content pb-4">
        <Row className="mb-3" xs="auto">
          <Col>
            <h4>Usuarios</h4>
          </Col>
          <Col className="text-right">
            {Number(user.rol) === 1 && (
              <Button variant="success" onClick={handleShow}>
                <FontAwesomeIcon icon={faPlus} /> Crear
              </Button>
            )}
          </Col>
        </Row>
        <Row>
          <Col xxl={Number(user.rol) === 1 ? 7 : 3} xl={Number(user.rol) === 1 ? 9 : 4} lg={Number(user.rol) === 1 ? 10 : 5}>
            <Table responsive id="table_recursos" className="column_4">
              <thead>
                <tr className="title_section">
                  <th>Nombre</th>
                  <th>Email</th>
                  {Number(user.rol) === 1 && <th>Editar</th>}
                  {Number(user.rol) === 1 && <th>Permisos</th>}
                  {Number(user.rol) === 1 && <th>Eliminar</th>}
                  {Number(user.rol) === 1 && <th></th>}
                </tr>
              </thead>
              <tbody className="table-group-divider align-middle">
                {userData.map((userx, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{userx.nombre}</td>
                      <td>{userx.mail}</td>
                      {Number(user.rol) === 1 && (
                        <td>
                          <Button
                            variant="success"
                            onClick={() => {
                              setIsEditing(true);
                              setIdx(userx.id);
                              setName(userx.nombre);
                              setMail(userx.mail);
                              setMail2(userx.mail);
                              setPass(userx.password);
                              setRol(userx.rol);
                              handleShow();
                            }}>
                            <FontAwesomeIcon icon={faPencil} />
                          </Button>
                        </td>
                      )}
                      {Number(user.rol) === 1 && (
                        <td>
                          <Button
                            variant="secondary"
                            onClick={() => {
                              setIdx(userx.id);
                              showAppsModal(userx.id);
                            }}>
                            <FontAwesomeIcon icon={faListDots} />
                          </Button>
                        </td>
                      )}
                      {Number(user.rol) === 1 && (
                        <td>
                          {userx.noDelete}
                          <Button
                            variant="danger"
                            disabled={isLoading || userx.noDelete}
                            onClick={() => {
                              handleDel(userx.id, idx);
                            }}>
                            {isLoading === true ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <FontAwesomeIcon icon={faTrash} />}
                          </Button>
                        </td>
                      )}
                      {Number(user.rol) === 1 && <td>{userx.noDelete && <span className="text-danger">En uso</span>}</td>}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{!isEditing ? "Añadir " : "Editar "}Usuario</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="newUserName">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => {
                  setName(e.target.value);
                }}
                value={name}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="newUserMail">
              <Form.Label>Correo</Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => {
                  setMail(e.target.value);
                }}
                value={mail}
              />
            </Form.Group>
            {!isEditing && (
              <Form.Group className="mb-3" controlId="newPriceCostoHoraVenta">
                <Form.Label>Contraseña</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) => {
                    setPass(e.target.value);
                  }}
                  value={pass}
                />
              </Form.Group>
            )}
            {/*<Form.Group className="mb-3" controlId="newPricePosition">
              <Form.Label>Rol</Form.Label>
              <Form.Select
                value={rol}
                onChange={(e) => {
                  setRol(Number(e.target.value));
                }}>
                <option key={1} value={2}>
                  Usuario
                </option>
                <option key={0} value={1}>
                  Administrador
                </option>
              </Form.Select>
              </Form.Group>*/}
            <p className="text-danger">{existValue && "Usuario ya registrado"}</p>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
          <Button variant="primary" disabled={isLoading} onClick={!isEditing ? handleSave : handleUpdate}>
            {isLoading === true ? "" : "Guardar "}
            {isLoading === true ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <FontAwesomeIcon icon={faSave} />}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showModalApp} onHide={handleAppsModalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Asignar Aplicaciones</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {userApps.map((app, idx) => {
            return (
              <Row key={idx}>
                <Col>
                  <Form.Check
                    type="checkbox"
                    id={app.id}
                    label={app.nombre}
                    checked={app.checked}
                    onChange={(e) => {
                      handleAppsCheck(Number(e.target.id));
                    }}
                  />
                </Col>
                <Col>
                  <Form.Select
                    value={app.rol}
                    onChange={(e) => {
                      handleAppsRol(idx, Number(e.target.value));
                    }}>
                    <option key={"1a"} value={2}>
                      Usuario
                    </option>
                    <option key={"1b"} value={1}>
                      Administrador
                    </option>
                  </Form.Select>
                </Col>
              </Row>
            );
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleAppsModalClose}>
            Cerrar
          </Button>
          <Button variant="primary" disabled={isLoading} onClick={handleUpdateUserApps}>
            {isLoading === true ? "" : "Guardar "}
            {isLoading === true ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <FontAwesomeIcon icon={faSave} />}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Users;
