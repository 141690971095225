import http from "../http-common";
import axios from "axios";

class DataService {
  create(data) {
    return http.post("/user", data);
  }

  login(data) {
    return http.post("/login", data);
  }

  lostPassword(data) {
    return http.post("/lostPassword", data);
  }

  update(data) {
    return http.put("/user", data);
  }

  getAll() {
    return http.get("/user");
  }

  delete(id) {
    return http.delete(`/user/${id}`);
  }

  getResetPasswordTk(token) {
    return http.post("/gibt", { tkn: token });
  }

  getApps(id) {
    return http.get("/apps/" + id);
  }

  getAllApps() {
    return http.get("/apps");
  }

  updateUserApps(data) {
    return http.put("/updateUserApps", data);
  }

  verifyFirma(cod) {
    return axios.get(`${process.env.REACT_APP_AP2}/verifyFirma/${cod}`);
  }

  findFirmaImgByCodigo(cod) {
    return axios.get(`https://qigo043dp3.execute-api.us-east-1.amazonaws.com/apirpa/getObject?key=files/gen/${cod}.png`);
  }
}

/* eslint-disable import/no-anonymous-default-export */
export default new DataService();
